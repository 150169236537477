import React, { useState, useEffect } from 'react';
import { get, post, put, del } from '../services/ApiService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillTrash3Fill,BsPencilSquare,BsSearch,BsXCircle,BsCheckCircle   } from "react-icons/bs";
import Select from 'react-select';




const OrderReturns = ({ onAddItem, onUpdateItem, onDeleteItem }) => {
  const [data, setData] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchFilters, setSearchFilters] = useState({});
  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [itemOptions, setItemOptions] = useState([]);
  const [newItem, setNewItem] = useState({
    order_number: '',
    date_returned: '',
    item:'',
    ppid:'',
    quantity:'',
    comment: '',
    condition:''
  });
  const [updateItem, setUpdateItem] = useState({
    order_number: '',
    date_returned: '',
    comment: '',
    item:'',
    ppid:'',
    quantity:'',
    id: '',
    condition:''
  });
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [filterColumn, setFilterColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    fetchData(); // Fetch initial data on component mount
    fetchPipData(); // Fetch item options
  }, []);

  const fetchData = async () => {
    try {
      const response = await get('api/orderReturns');
      const sortedData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setData(sortedData);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  const fetchPipData = async () => {
    try {
      const response = await get('api/ppidProductInfo/ppid');
      const newOptions = response.data.map((item) => ({ product: item.product, id: item.id }));
      setItemOptions(newOptions);
    } catch (error) {
      console.error('Error fetching ppid:', error);
    }
  };


  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortDirection('asc');
    }
  };

  const handleSearch = (e, column) => {
    const { value } = e.target;
    setSearchFilters(prevFilters => ({ ...prevFilters, [column]: value }));
  };

  const handleColumnHeaderClick = (columnKey) => {
    if (!searchIconClicked) {
      handleSort(columnKey);
    }
  };
  

  const handleSearchIconClick = (columnKey) => {
    // setSortedColumn(null); // Reset sorting
    setFilterColumn(columnKey);
    setShowFilterPopover(true);
    setShowCalendar(columnKey === 'date_returned');
    setSearchIconClicked(true); // Set search icon clicked
  };

  const handleFilterPopoverClose = () => {
    setShowFilterPopover(false);
    setSearchIconClicked(false); // Reset search icon clicked
    setSearchFilters('');
  };

  const handleDateSelect = (date) => {
    // Adjust for time zone offset
    const timezoneOffsetInMs = date.getTimezoneOffset() * 60 * 1000;
    const adjustedDate = new Date(date.getTime() - timezoneOffsetInMs);
  
    // Format the adjusted date
    const formattedDate = adjustedDate.toISOString().split('T')[0];
  
    // Update the searchFilters state with the adjusted date
    setSearchFilters(prevFilters => ({ ...prevFilters, date: formattedDate }));
  
    // Hide the calendar after selection
    setShowCalendar(false);
  };
  

  const filteredData = data.filter(item => {
    return Object.entries(searchFilters).every(([key, value]) => {
      if (!value || key === 'id') return true; // Exclude 'id' from search
      if (key === 'date_returned') {
        const itemDate = new Date(item[key]).toISOString().split('T')[0];
        const searchDate = new Date(value).toISOString().split('T')[0];
        return itemDate.includes(searchDate);
      }
      return String(item[key]).toLowerCase().includes(value.toLowerCase());
    });
  });

  const sortedData = sortedColumn
    ? filteredData.slice().sort((a, b) => {
      const valueA = a[sortedColumn];
      const valueB = b[sortedColumn];
      if (sortedColumn === 'date_returned') {
        return sortDirection === 'asc' ? new Date(valueA) - new Date(valueB) : new Date(valueB) - new Date(valueA);
      }
      if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    })
    : filteredData.slice();

  const handleAddItem = async () => {
    try {
      // console.log(newItem.item.value)
      // const [splitNewPPID, splitNewItem] = newItem.item.value.split(' - ');
      const cleansedNewItem = {
        ...newItem,
        // item: splitNewItem,
        // ppid: splitNewPPID,
      };

      console.log(cleansedNewItem);


      if (
        !cleansedNewItem.order_number ||
        cleansedNewItem.order_number === '' ||
        !cleansedNewItem.date_returned ||
        cleansedNewItem.date_returned === ''
      ) {
        alert('Ensure all info is added');
        return;
      }

      // toggleOpen = () => {
      //   this.setState(state => ({ isOpen: !state.isOpen }));
      // };
      // onSelectChange = value => {
      //   this.toggleOpen();
      //   this.setState({ value });
      // };

      await post('api/orderReturns', cleansedNewItem);

      setNewItem({
        order_number: '',
        date_returned: '',
        comment: '',
        item:'',
        ppid:'',
        quantity:'',
        condition:''
      });

      fetchData();

      //onAddItem();
    } catch (error) {
      console.error('Error adding stock loss:', error);
    }
  };

  const handleUpdateItem = async () => {
    try {
      // const [splitUpdatePPID, splitUpdateItem] = updateItem.item.split(' - ');

      const cleansedUpdateItem = {
        ...updateItem,

      };

      if (
        !cleansedUpdateItem.order_number ||
        cleansedUpdateItem.order_number === '' ||
        !cleansedUpdateItem.date_returned ||
        cleansedUpdateItem.date_returned === ''
      ) {
        alert('Ensure all info is added');
        return;
      }

      await put(`api/orderReturns/${selectedItemId}`, cleansedUpdateItem);
      setSelectedItemId(null);
      setUpdateItem({
        order_number: '',
        date_returned: '',
        comment: '',
        item:'',
        ppid:'',
        quantity:'',
        id: '',
        condition:''
      });

      fetchData();
      onUpdateItem();
    } catch (error) {
      console.error('Error adding invoice:', error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      // Display confirmation dialog
      const isConfirmed = window.confirm('Are you sure you want to delete this item?');

      if (isConfirmed) {
        await del(`api/orderReturns/${id}`);
        fetchData(); // Refresh data after deleting
        onDeleteItem(); // Notify parent component about the deletion
      }
    } catch (error) {
      console.error('Error deleting invoice:', error);
    }
  };

  const handleUpdateButtonClick = (item) => {
    setSelectedItemId(item.id);
    setUpdateItem({ ...item }); // Ensure 'updateItem' is populated correctly
    //console.log(updateItem);
  };

  const handleCloseUpdateItem = () => {
    setSelectedItemId(null);
    setUpdateItem({
        order_number: '',
        date_returned: '',
        comment: '',
        item:'',
        ppid:'',
        quantity:'',
        id: '',
        condition:''
    });

  }



  // Filter options based on search term
  const filteredOptions = itemOptions.filter(item => item.product.toLowerCase().includes(searchTerm.toLowerCase()));
  
  // Update search term when input changes
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '15px',
      color: 'black',
      padding: '5px',
      borderWidth: '0px',
      width: '500px'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 0, // remove border radius
    }),
    option: (provided, state) => ({
      ...provided,
      width: '500px',
      fontSize: '14px'
      // backgroundColor: state.isSelected ? '#007bff' : 'transparent', // highlight selected option
      // color: state.isSelected ? '#fff' : '#000', // text color for selected and non-selected options
    }),
  };

  

  return (

    <div>
    <h2><b>Order Returns</b></h2>

    <div className="add-invoice">
      <button className="add-invoice-button" onClick={() => setShowPopup(true)}>
        + Add Returned items
      </button>
    </div>

    {showPopup && (
      <div className="popup">
        <div className="popup-inner">
          <div className="update-form">
            {Object.keys(newItem).map((key) => (
              <div className="form-group" key={key}>
     {key === 'item' ? (

<div className="field-container">
                 <label className='form-labels'>
                       
                 Item:
                 </label >

<div className="select-dropdown">
<Select
  value={
    newItem.item && newItem.ppid
      ? { value: `${newItem.ppid} - ${newItem.item}`, label: `pp${newItem.ppid} - ${newItem.item}` }
      : { value: "", label: "Select item" }
  }
  onChange={(selectedOption) => setNewItem({ ...newItem, item: selectedOption.item, ppid: selectedOption.ppid })}
  options={itemOptions.map(item => ({ value: `${item.id} - ${item.product}`, label: `pp${item.id} - ${item.product}`, item: item.product, ppid: item.id }))}
  styles={customStyles}
/>

  
</div>
</div>


) : key === 'condition' ? (
    <div >
<label className='form-labels'>{key}:
        <select className='form-fields'
        value={newItem.condition}
        onChange={(e) => setNewItem({ ...newItem,  [key]: e.target.value })}
        >
        <option value="" disabled>Select an option</option>
        <option value="Refundable">Refundable</option>
        <option value="Not-Refundable">Not-Refundable</option>

        </select>
    </label> 
    </div>
  ): key !== 'ppid' ? (
  <div className="field-container">
    <label className='form-labels'>{key}:</label>
    <input className='form-fields'
      type={key === 'date_returned' ? 'date' : key === 'quantity' ? 'number' : 'text'}
      step={key === 'quantity' ? '1' : 'any'}
      value={newItem[key]}
      onChange={(e) => setNewItem({ ...newItem, [key]: e.target.value })}
    />
  </div>
                ) : null}
              </div>
            ))}
            <button className="update-button" onClick={handleAddItem}>
             <BsCheckCircle />
            </button>
            <button className="close-button" onClick={() => setShowPopup(false)}>
           < BsXCircle />
          </button>
          </div>
        </div>
      </div>
    )}
<div className='table_back'>
      <table>
        <thead>
          <tr>
            {data.length > 0 &&
              Object.keys(data[0]).map((key) =>
                key !== 'id' ? (
<th key={key} >
  <div onClick={() => handleColumnHeaderClick(key)}>
  {key}{' '}
  {!searchIconClicked && sortedColumn === key && (sortDirection === 'asc' ? '▲' : '▼')}

  </div>

  <div>
  
  <button className="search-icon" onClick={() => handleSearchIconClick(key)}>
    < BsSearch  className="icon-search"/>
    </button>

  </div>


  {filterColumn === key && showFilterPopover && (
    <div className="popover">
      {key !== 'date_returned' ? (
        <input
          type="text"
          placeholder={`Search ${key}`}
          value={searchFilters[key] || ''}
          onChange={(e) => handleSearch(e, key)}
        />
      ) : (
        <DatePicker
          selected={searchFilters.date ? new Date(searchFilters.date) : null}
          onChange={handleDateSelect}
          dateFormat="yyyy-MM-dd"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      )}
      <button onClick={handleFilterPopoverClose}><BsXCircle className="icon-search"/></button>
    </div>
  )}
</th>
                ) : null
              )}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item) => (
            <React.Fragment key={item.id}>
              <tr>
                {Object.keys(item).map((key) =>
                  key !== 'id' ? (
                    <td key={key}>{key === 'date_returned' ? new Date(item[key]).toISOString().split('T')[0] : item[key]}</td>
                  ) : null
                )}
                <td>
                  <button className="action-button" onClick={() => handleUpdateButtonClick(item)}>
                  <BsPencilSquare />
                  </button>
                  <button className="action-button" onClick={() => handleDeleteItem(item.id)}>
                  <BsFillTrash3Fill />
                  </button>
                </td>
              </tr>
              {selectedItemId === item.id && (
                <tr>
                  <td colSpan={Object.keys(item).length}>
                    <div>
                      <h3>Update</h3>
                      <div className="update-form">
                        {Object.keys(updateItem).map((key) => (
                          <div key={key} className="form-group">
                            {key === 'item' ? (
                              <div className="field-container">
                 <label className='form-labels'>
                       
                 Item:
                 </label >

<div className="select-dropdown">
<Select
      value={{ value: updateItem.ppid, label: `pp${updateItem.ppid} - ${updateItem.item}` }}
      onChange={(selectedOption) => setUpdateItem({ ...updateItem, item: selectedOption.item, ppid: selectedOption.ppid })}
      options={itemOptions.map(item => ({ value: item.id, label: `pp${item.id} - ${item.product}`, item: item.product, ppid: item.id }))}
      
      styles={customStyles}
    />
</div>
</div>

                            ) : key === 'date_returned' ? (
                              <div >
                                <label className='form-labels'>{key}:</label>
                                <input className='form-fields'
                                  type="date"
                                  value={updateItem.date ? new Date(updateItem.date).toISOString().split('T')[0] : ''}
                                  onChange={(e) => setUpdateItem({ ...updateItem, [key]: e.target.value })}
                                />
                              </div>
                            ) : key === 'condition' ? (
                                <div >
                        <label className='form-labels'>{key}:
                                    <select className='form-fields'
                                    value={updateItem.condition}
                                    onChange={(e) => setUpdateItem({ ...updateItem,  [key]: e.target.value })}
                                    >
                                    <option value="Refundable">Refundable</option>
                                    <option value="Not-Refundable">Not-Refundable</option>

                                    </select>
                                </label> 
                                </div>
                              ) :  key !== 'ppid' && key !== 'id' ? (
                              <div>  
                                <label className='form-labels'>{key}:</label>
                                <input className='form-fields'
                                  type={key === 'date_returned' ? 'date' : key === 'quantity' ? 'number' : 'text'}
                                  step={key === 'quantity' ? '1' : 'any'}
                                  value={updateItem[key]}
                                  onChange={(e) => setUpdateItem({ ...updateItem, [key]: e.target.value })}
                                />
                              </div>
                            ) : null}
                          </div>
                        ))}
                        <button className="update-button" onClick={handleUpdateItem}>
                        <BsCheckCircle />
                        </button>
                        <button className="close-button" onClick={handleCloseUpdateItem}>
                        < BsXCircle />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
    </div>

  );
};

export default OrderReturns;
