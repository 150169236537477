import React, { useState, useEffect } from 'react';
import { get, post, del } from '../services/ApiService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillTrash3Fill,BsPencilSquare,BsSearch,BsXCircle,BsCheckCircle,BsInfoCircle   } from "react-icons/bs";
import Select from 'react-select';


const TableStockCount = () => {
  const [data, setData] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPpidPopup, setShowPpidPopup] = useState(false);
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchFilters, setSearchFilters] = useState({});
  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const [showThresholdOnly, setShowThresholdOnly] = useState(false); // New state for toggle
  const [showCalendar, setShowCalendar] = useState(false);
  const [itemOptions, setItemOptions] = useState([]);
  const [dataPIP, setDataPIP] = useState([]);
  const [dataPPID, setDataPPID] = useState([]);
  const [pendingInvoices, setPendingInvoices] = useState([]);
  const [sourcingNotes, setSourcingNotes] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [newNote, setNewNote] = useState({
    product:'',
      ppid: '',
      note: ''
  });
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    // Retrieve the value from localStorage or use a default value

    if (typeof window !== 'undefined') {
      // Your initialization code that relies on the browser environment
      return parseInt(localStorage.getItem('itemsPerPage')) || 100;
    }
    else{
      return 100;
    }
  });
  const [loading, setLoading] = useState(true);

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [filterColumn, setFilterColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPpid, setSelectedPpid] = useState('');
  const [pipList, setPipList] = useState([]);
  const [notesList, setNotesList] = useState([]);

    
  useEffect(() => {


    fetchSourcingNotes();
    fetchData();
    fetchPpidData();


  }, []);




  const handleInfoButtonClick = async (item) => {
    try {

      setSelectedPpid(item.ppid)
      setShowPpidPopup(true);
      getPipNotesBySinglePpid(item.ppid)

    }
   catch (error) {
    console.error('Error on info button click:', error);
  }
};


  const fetchPendingData = async () => {
    try {
      const response = await get('api/invoices');
      //console.log(response)
      const filteredOptions = response.data.filter((item) => item.received === 'PENDING');

      const sortedData = filteredOptions.map((item) => ({ qnt: item.approved_qnt, pip: item.pip }));

      setPendingInvoices(sortedData);

      return(sortedData);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  const fetchSourcingNotes = async () => {
    try {
      const response = await get('api/sourcingNotes');

      const sourcingNotes = response.data.map((item) => ({
        ...item,
        ppid: parseInt(item.ppid?.replace(/^pp/, ''), 10), // Remove "pp" prefix from ppid
      }));


      setSourcingNotes(sourcingNotes);
      //console.log(response)
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await get('api/stockCount');
      
      
      const pipData =  await fetchPipData();
  
      const pendingInvoicesData = await fetchPendingData();
  
  
      // Parse stock count data
      const stockcount = response.data.map((item) => ({
        ...item,
        ppid: parseInt(item.ppid?.replace(/^pp/, ''), 10),
      }));
  
      // Left join with the latest pendingInvoicesData
      const pendingWithPPID = leftJoin(pendingInvoicesData, pipData, 'pip', 'pip', (matched) => ({
        ppid: matched.ppid || null,
      }));
  

      // Aggregate data
      const aggregatedData = stockcount.map((dataItem) => {
        const relatedPending = pendingWithPPID.filter(
          (pendingItem) => pendingItem.ppid === dataItem.ppid
        );
        const pending = relatedPending.reduce((sum, item) => sum + (item.qnt || 0), 0);
  
        return {
          product: dataItem.product,
          type: dataItem.type,
          ppid: dataItem.ppid,
          adjustments: dataItem.adjustments,
          reserved: dataItem.reserved,
          stockcount: dataItem.stockcount,
          pending,
          last7: dataItem.last7 ? parseFloat(dataItem.last7.toFixed(2)) : 0,
          last30: dataItem.last30 ? parseFloat(dataItem.last30.toFixed(2)) : 0,
          min_unit_price_ex_vat: dataItem.min_unit_price_ex_vat,
          max_unit_price_ex_vat: dataItem.max_unit_price_ex_vat,
        };
      });
  
  
      setData(aggregatedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stock count:', error);
      setLoading(false);
    }
  };
  


  const getPipNotesBySinglePpid = async (ppid) => {
    try {
      // Ensure dataPIP is loaded
      if (!dataPIP || dataPIP.length === 0) {
        throw new Error('dataPIP is not loaded.');
      }
      
      

      
      // Filter dataPIP for the specific ppid
      const filteredPips = dataPIP.filter((item) => item.ppid === ppid).map((item) => item.pip);

      const sourcingNotesSingle = sourcingNotes.filter((item) => item.ppid === ppid).map((item) => item.notes);

      console.log(sourcingNotesSingle);
      
      setNotesList(sourcingNotesSingle || []);

      setPipList(filteredPips || []);

      console.log(filteredPips,sourcingNotes)

    } catch (error) {
      console.error('Error grouping PIP by single PPID:', error);
      return { ppid, pipList: [] }; // Return empty list on error
    }
  };


  
  

  useEffect(() => {
    paginateData();
  }, [currentPage, itemsPerPage, sortedData]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('itemsPerPage', itemsPerPage.toString());
      // Your initialization code that relies on the browser environment
    }
    else{
    // Save the 'Items per page' value to localStorage

  }
  }, [itemsPerPage]);

  useEffect(() => {
    paginateData();
  }, [currentPage, itemsPerPage, sortedData]);


const leftJoin = (array1, array2, key1, key2, additionalColumns) =>
  array1.map((item1) => {
    const matchedItem = array2.find((item2) => item1[key1] === item2[key2]) || {};
    return { ...item1, ...additionalColumns(matchedItem) };
  });
  
  const fetchPipData = async () => {
    try {
      const response = await get('api/ppidProductInfo/pip');
      const newOptions = response.data.map((item) => ({ ppid: item.ppid, pip: item.pip }));
      setDataPIP(newOptions);

      return(newOptions);
    } catch (error) {
      console.error('Error fetching pip:', error);
    }
  };

  const fetchPpidData = async (tab) => {
    try {
      const response = await get(`api/ppidProductInfo/ppid`);
      setDataPPID(response.data);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };
  

  
  const handleAddNote = async () => {
    try {
      // console.log(newItem.item_name.value)
      // const [splitNewPPID, splitNewItem] = newItem.item_name.value.split(' - ');

      if (!newNote.ppid) {
        alert('A product and note is required when submiiting. Ensure a Product is selected to view Note');
        return;
      }

      else if (!newNote.note) {
        alert('A note is required when submiiting. Ensure a note is added');
        return;
      }


      await post('api/sourcingNotes', newNote);

      setNewNote({
        product:'',
        ppid: '',
        note: ''
      });

      fetchSourcingNotes();

      //onAddItem();
    } catch (error) {
      console.error('Error adding stock loss:', error);
    }
  };
  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortDirection('asc');
    }
  };

  const handleSearch = (e, column) => {
    const { value } = e.target;
    setSearchFilters(prevFilters => ({ ...prevFilters, [column]: value }));
  };

  const handleColumnHeaderClick = (columnKey) => {
    if (!searchIconClicked) {
      handleSort(columnKey);
    }
  };
  

  const handleSearchIconClick = (columnKey) => {
    // setSortedColumn(null); // Reset sorting
    setFilterColumn(columnKey);
    setShowFilterPopover(true);
    setShowCalendar(columnKey === 'date');
    setSearchIconClicked(true); // Set search icon clicked
  };

  const handleFilterPopoverClose = () => {
    setShowFilterPopover(false);
    setSearchIconClicked(false); // Reset search icon clicked
    setSearchFilters('');
  };

  const handleDateSelect = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    setSearchFilters(prevFilters => ({ ...prevFilters, date: formattedDate }));
    setShowCalendar(false); // Hide the calendar after selection
  };
  const filteredData = data.filter(item => {
    return Object.entries(searchFilters).every(([key, value]) => {
      if (!value || key === 'id') return true; // Exclude 'id' from search
      if (key === 'date') {
        const itemDate = new Date(item[key]).toISOString().split('T')[0];
        const searchDate = new Date(value).toISOString().split('T')[0];
        return itemDate.includes(searchDate);
      }
      return String(item[key]).toLowerCase().includes(value.toLowerCase());
    });
  });

  const sortedDataPlus = sortedColumn
    ? filteredData.slice().sort((a, b) => {
      const valueA = a[sortedColumn];
      const valueB = b[sortedColumn];
      if (sortedColumn === 'date') {
        return sortDirection === 'asc' ? new Date(valueA) - new Date(valueB) : new Date(valueB) - new Date(valueA);
      }
      if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    })
    : filteredData.slice();

const thresholdFilteredData = showThresholdOnly
    ? sortedDataPlus.filter(item => item.stockcount < 15 * item.last7)
    : sortedDataPlus;

  const sortData = (data, sortBy, order) => {
    const sorted = [...data].sort((a, b) => {
      if (order === 'asc') {
        return a[sortBy] - b[sortBy];
      } else {
        return b[sortBy] - a[sortBy];
      }
    });

    setSortedData(sorted);
  };

  const paginateData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setSortedData(data.slice(startIndex, endIndex));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const handleAddItem = async () => {

    console.log('newItem',newNote);

      
    try {


        await post('api/invoices', newNote);
        setNewNote({
            ppid: '',
            note: '',
          });
          
      fetchSourcingNotes(); // Refresh data after adding
      // onAddItem(); // Notify parent component about the addition
    } catch (error) {
      console.error('Error adding invoice:', error);
    }
  };


  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '15px',
      color: 'black',
      padding: '5px',
      borderWidth: '0px',
      width: '500px'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 0, // remove border radius
    }),
    option: (provided, state) => ({
      ...provided,
      width: '500px',
      fontSize: '14px'
      // backgroundColor: state.isSelected ? '#007bff' : 'transparent', // highlight selected option
      // color: state.isSelected ? '#fff' : '#000', // text color for selected and non-selected options
    }),
  };
  const cellStyle = (stockCount, last7) => {
    const thresholdRed = 10 * last7;
    const thresholdAmber = 15 * last7;

    if (stockCount < thresholdRed) {
      return { backgroundColor: 'red' };
    } else if (stockCount < thresholdAmber) {
      return { backgroundColor: 'orange' };
    } else {
      return {};
    }
  };

  return (

    <div>

<div className="add-invoice">
  <button className="add-invoice-button" onClick={() => setShowPopup(true)}>
    + Add Product Notes
  </button>
</div>

{showPopup && (
  <div className="popup">
    <div className="popup-inner">
      <div className="update-form">
        <label className="form-labels" style={{ display: "block", marginBottom: "10px" }}>
          Item:
        </label>
        <div className="select-dropdown" style={{ marginBottom: "15px" }}>
          <Select
            value={
              newNote.product && newNote.ppid
                ? { value: `${newNote.ppid} - ${newNote.product}`, label: `pp${newNote.ppid} - ${newNote.product}` }
                : { value: "", label: "Select item" }
            }
            onChange={(selectedOption) => {
              setNewNote({ ...newNote, product: selectedOption.product, ppid: selectedOption.ppid });
              getPipNotesBySinglePpid(selectedOption.ppid);
            }}
            options={dataPPID.map((item) => ({
              value: `${item.id} - ${item.product}`,
              label: `pp${item.id} - ${item.product}`,
              product: item.product,
              ppid: item.id,
            }))}
            styles={customStyles}
          />
        </div>

        {/* Conditional Content */}
        {newNote.ppid ? (
          <>
            <textarea
              style={{
                display: "block",
                marginBottom: "15px",
                width: "100%",
                height: "100px",
              }}
              value={newNote.note !== '' ? newNote.note : notesList[0] || ""}
              onChange={(e) => setNewNote({ ...newNote, note: e.target.value })}
              placeholder="Enter your note here"
            />
          </>
        ) : (
          <p style={{ display: "block", margin: "15px" }}>
            Please select an item to view or add a note.
          </p>
        )}

        <button className="update-button" style={{ marginRight: "10px" }} onClick={handleAddNote}>
          <BsCheckCircle />
        </button>
        <button className="close-button" onClick={() => setShowPopup(false)}>
          <BsXCircle />
        </button>
      </div>
    </div>
  </div>
)}




      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h2>Stock Count</h2>
          {/* <div>
            <label>Items per page:</label>
            <select value={itemsPerPage} onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </select>
          </div> */}

          <label>
            Show Only Low stock - Depleting within 15 days:
            <input
              type="checkbox"
              checked={showThresholdOnly}
              onChange={() => setShowThresholdOnly(!showThresholdOnly)}
            />
          </label>  
          <table>
            <thead>
              {data.length > 0 && (
                <tr>
 {data.length > 0 &&
              Object.keys(data[0]).map((key) =>
                key !== 'id'  && key !=='type' && key !=='ppid' && key !=='adjustments'? (
<th key={key} >
  <div onClick={() => handleColumnHeaderClick(key)}>
  {key ==='min_unit_price_ex_vat'? 'Low Buy Price': key ==='max_unit_price_ex_vat'? 'Max Buy Price':key}{' '}
  {!searchIconClicked && sortedColumn === key && (sortDirection === 'asc' ? '▲' : '▼')}

  </div>

  <div>
  
  <button className="search-icon" onClick={() => handleSearchIconClick(key)}>
    < BsSearch  className="icon-search"/>
    </button>

  </div>
  {filterColumn === key && showFilterPopover && (
    <div className="popover">
      {key !== 'date' ? (
        <input
          type="text"
          placeholder={`Search ${key}`}
          value={searchFilters[key] || ''}
          onChange={(e) => handleSearch(e, key)}
        />
      ) : (
        <DatePicker
          selected={searchFilters.date ? new Date(searchFilters.date) : null}
          onChange={handleDateSelect}
          dateFormat="yyyy-MM-dd"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      )}
      <button onClick={handleFilterPopoverClose}><BsXCircle className="icon-search"/></button>
    </div>
  )}
</th>
                ) : null
              )}
              <th>Info</th>
                </tr>
              )}
            </thead>
            <tbody>
  {thresholdFilteredData.map((item) => (
    <React.Fragment key={item.ppid}>
      <tr
        // style={{
        //   backgroundColor: item.pending > 0 ? 'lightblue' : 'transparent', // Light blue background for pending > 0
        // }}
      >
        {Object.keys(item).map((key) =>
          key !== 'id' && key !== 'type' && key !== 'ppid' && key !== 'adjustments' && (
            <td
              key={key}
              style={key === 'stockcount' ? cellStyle(item.stockcount, item.last7) : key === 'pending' ? item.pending != 0 ? {
                backgroundColor: 'lightblue'} : {} :{}}
              title={item[key]}
            >
              {item[key]}
            </td>
          )
        )}
        <td>
          <button className="action-button" onClick={() => handleInfoButtonClick(item)}>
            <BsInfoCircle />
          </button>
        </td>
      </tr>
    </React.Fragment>
  ))}
</tbody>


          </table>

          {showPpidPopup && (
  <div className="popup" id="notePopup">
    <div className="popup-content">
      <button
        className="close-button"
        onClick={() => setShowPpidPopup(false)}
      >
        &times;
      </button>
      <h3>PIP Values</h3>

      <div style={{ padding: '10px' }}>
            {pipList.map((pip, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                <code style={{ marginRight: '10px' }}>{pip}</code>
                <button
                  style={{
                    padding: '5px 10px',
                    cursor: 'pointer',
                    background: '#007BFF',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                  }}
                  onClick={() => navigator.clipboard.writeText(pip )}
                >
                  Copy
                </button>
              </div>
            ))}

            <div style={{ padding: '10px' }}>
              {notesList.map((note, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <h3 style={{ marginRight: '10px' }}>{ note }</h3>
                </div>
              ))}
            </div>

        </div>
      </div>
    </div>
  )}


          <div>
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </button>
            <span>{currentPage}</span>
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage * itemsPerPage >= data.length}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};
export default TableStockCount;
