import React, { useState, useEffect } from 'react';
import {
  BsArrowsCollapseVertical, BsHouse, BsBarChartLine,
  BsFileEarmarkText, BsHourglassSplit, BsClipboard2Minus,
  BsClipboard2Plus, BsDatabaseAdd, BsTag, BsBoxArrowRight, BsBox2, BsFillInboxesFill
} from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

const LeftBar = ({ leftBarCollapsed, setLeftBarCollapsed, handleLogout, setSelectedTab }) => {
  const location = useLocation();

  // Using `selectedTab` for CSS checks to sync with selected state
  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];
    setSelectedTab(currentPath);
  }, [location, setSelectedTab]);

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
    localStorage.setItem('selectedTab', tabName);
  };

  let selectedTab = localStorage.getItem('selectedTab');

  return (
    <div className={`left-bar ${leftBarCollapsed ? 'collapsed' : ''}`}>
      <div className="collapse-btn" onClick={() => setLeftBarCollapsed(!leftBarCollapsed)}>
        <BsArrowsCollapseVertical className="icon-collapse" />
      </div>
      <ul>
        <li className={selectedTab === 'dashboard' ? 'selected-tab' : ''}>
          <Link to="/Dashboard" onClick={() => handleTabClick('dashboard')}>
            <span className="icon">
              <BsHouse className={selectedTab === 'dashboard' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Dashboard'}
          </Link>
        </li>

        <li className={selectedTab === 'stockCount' ? 'selected-tab' : ''}>
          <Link to="/TableStockCount" onClick={() => handleTabClick('stockCount')}>
            <span className="icon">
              <BsBarChartLine className={selectedTab === 'stockCount' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Stock Count'}
          </Link>
        </li>

        <li className={selectedTab === 'invoices' ? 'selected-tab' : ''}>
          <Link to="/TableInvoices" onClick={() => handleTabClick('invoices')}>
            <span className="icon">
              <BsFileEarmarkText className={selectedTab === 'invoices' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'All Invoices'}
          </Link>
        </li>

        <li className={selectedTab === 'pending' ? 'selected-tab' : ''}>
          <Link to="/PendingInvoices" onClick={() => handleTabClick('pending')}>
            <span className="icon">
              <BsHourglassSplit className={selectedTab === 'pending' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Pending Goods'}
          </Link>
        </li>

        <li className={selectedTab === 'stockLost' ? 'selected-tab' : ''}>
          <Link to="/TableStockLost" onClick={() => handleTabClick('stockLost')}>
            <span className="icon">
              <BsClipboard2Minus className={selectedTab === 'stockLost' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Stock Lost'}
          </Link>
        </li>

        <li className={selectedTab === 'stockReplenished' ? 'selected-tab' : ''}>
          <Link to="/TableStockReplenished" onClick={() => handleTabClick('stockReplenished')}>
            <span className="icon">
              <BsClipboard2Plus className={selectedTab === 'stockReplenished' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Stock Replenished'}
          </Link>
        </li>

        <li className={selectedTab === 'addProducts' ? 'selected-tab' : ''}>
          <Link to="/addProducts" onClick={() => handleTabClick('addProducts')}>
            <span className="icon">
              <BsDatabaseAdd className={selectedTab === 'addProducts' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Add Products'}
          </Link>
        </li>

        <li className={selectedTab === 'tableSku' ? 'selected-tab' : ''}>
          <Link to="/tableSku" onClick={() => handleTabClick('tableSku')}>
            <span className="icon">
              <BsTag className={selectedTab === 'tableSku' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Product SKUs'}
          </Link>
        </li>

        <li className={selectedTab === 'tableSupplierClaims' ? 'selected-tab' : ''}>
          <Link to="/tableSupplierClaims" onClick={() => handleTabClick('tableSupplierClaims')}>
            <span className="icon">
              <BsFillInboxesFill className={selectedTab === 'tableSupplierClaims' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Supplier Claims'}
          </Link>
        </li>

        <li className={selectedTab === 'tableOrderReturns' ? 'selected-tab' : ''}>
          <Link to="/tableOrderReturns" onClick={() => handleTabClick('tableOrderReturns')}>
            <span className="icon">
              <BsBox2 className={selectedTab === 'tableOrderReturns' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Customer Returns'}
          </Link>
        </li>

        <li>
          <Link to="#" onClick={handleLogout}>
            <span className="icon">
              <BsBoxArrowRight />
            </span>
            {!leftBarCollapsed && 'Logout'}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LeftBar;
