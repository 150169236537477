import React, { useState, useEffect } from 'react';
import { get, post, put, del } from '../services/ApiService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillTrash3Fill, BsPencilSquare, BsSearch, BsXCircle, BsCheckCircle } from "react-icons/bs";
import Select from 'react-select';

const SupplierClaims = ({ onUpdateItem, onDeleteItem }) => {
  const [showPopupClaim, setShowPopupClaim] = useState(false);

  const [showPopupConfirmClose, setShowPopupConfirmClose] = useState(false);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchFilters, setSearchFilters] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const [supplierClaims, setSupplierClaims] = useState([]);
  const [openClaimsFilter, setOpenClaimsFilter] = useState(true);
  const [newClaim, setNewClaim] = useState({
    order_date: '',
    claim_sent_date: '',
    claim_received_date: '',
    channel_ref: '',
    pip: '',
    item: '',
    pack_size: '',
    order_qnt: 0,
    received_qnt: 0,
    missing_qnt: 0,
    channel: '',
    reason_for_return_claim: '',
    claim_reference: '',
    notes: '',
    claim_received:'NO',
    status: 'OPEN'
  });

  const [updateClaim, setUpdateClaim] = useState({
    order_date: '',
    claim_sent_date: '',
    claim_received_date: '',
    channel_ref: '',
    pip: '',
    item: '',
    pack_size: '',
    order_qnt: 0,
    received_qnt: 0,
    missing_qnt: 0,
    channel: '',
    reason_for_return_claim: '',
    claim_reference: '',
    notes: '',
    claim_received:'NO',
    status: 'OPEN',
    id:''
  });

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [filterColumn, setFilterColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const reason_for_return_claim = ['Missing','Wrong Item']

  const columnHeaders = {'order_date': 'Order Date' ,
    'claim_sent_date': 'Claim Sent Date' ,
    'claim_received_date': 'Claim Received Date' ,
    'channel_ref': 'Channel Ref' ,
    'pip': 'PIP' ,
    'item': 'Item' ,
    'pack_size': 'Pack Size' ,
    'order_qnt': 'Order Quantity' ,
    'received_qnt': 'Received Quantity' ,
    'missing_qnt': 'Missing Quantity' ,
    'channel': 'Channel' ,
    'reason_for_return_claim': 'Reason' ,
    'claim_reference': 'Claim Reference' ,
    'status': 'Claim Status',
    'claim_received': 'Claim Received',
    'notes': 'Notes', }


  useEffect(() => {

    fetchSupplierClaims();
  }, []);
  

  const fetchSupplierClaims = async () => {
    const response = await get('api/supplierClaims');
    console.log(response.data)
    setSupplierClaims(response.data);
  };


  const validateData = (data) => {
    const missingValues = [];

    for (const key in data) {
      if (!data[key] || data[key] === '') {
        missingValues.push(key);
      }
    }

    if (missingValues.length > 0) {
      alert(`Please fill in the following fields: ${missingValues.join(', ')}`);
      return false;
    }

    return true;
  };



  const handleAddClaim = async () => {
    try {
      if (!validateData(newClaim)) return;

      await post('api/supplierClaims', newClaim);
      setNewClaim({
        order_date: '',
        claim_sent_date: '',
        claim_received_date: '',
        channel_ref: '',
        pip: '',
        item: '',
        pack_size: '',
        order_qnt: 0,
        received_qnt: 0,
        missing_qnt: 0,
        channel: '',
        reason_for_return_claim: '',
        claim_reference: '',
        notes: '',
        claim_received:'NO',
        status: 'OPEN'
      });

      await fetchSupplierClaims();
    } catch (error) {
      console.error('Error adding claim:', error);
    }
  };

  const handleUpdateReceivedItem = async (updateItem) => {
    try {
      // Update state immediately with the new item details
      setUpdateClaim(updateItem);
  
      // Update selected item ID
      setSelectedItemId(updateItem.id);
  
      // Log the updated status
      console.log(updateItem.status);
  
      // Check for conditions to show the confirmation popup
      if (updateItem.status === 'CLOSE' && (updateItem.claim_received_date === '' || updateItem.claim_received === 'NO')) {
        setShowPopupConfirmClose(true);
      } else {
        // Make the PUT request to update the claim
        await put(`api/supplierClaims/${updateItem.id}`, updateItem);
  
        // After updating, reset state if needed
        setSelectedItemId(null);
        setUpdateClaim({
          order_date: '',
          claim_sent_date: '',
          claim_received_date: '',
          channel_ref: '',
          pip: '',
          item: '',
          pack_size: '',
          order_qnt: 0,
          received_qnt: 0,
          missing_qnt: 0,
          channel: '',
          reason_for_return_claim: '',
          claim_reference: '',
          notes: '',
          claim_received: 'NO',
          status: 'OPEN',
          id: ''
        });
  
        // Refresh the claims list
        await fetchSupplierClaims();
  
        // Close the confirmation popup
        setShowPopupConfirmClose(false);
      }
    } catch (error) {
      console.error('Error updating Received:', error);
    }
  };
  

  

  const handleUpdateClaim = async () => {
    try {
      // const [splitUpdatePPID, splitUpdateItem] = updateItem.item_name.split(' - ');

      const cleansedUpdateItem = {
        ...updateClaim,

      };


      if (
        !cleansedUpdateItem.missing_qnt ||
        cleansedUpdateItem.missing_qnt === '' ||
        !cleansedUpdateItem.pip ||
        cleansedUpdateItem.pip === '' ||
        !cleansedUpdateItem.reason_for_return_claim ||
        cleansedUpdateItem.reason_for_return_claim === '' ||
        !cleansedUpdateItem.claim_reference ||
        cleansedUpdateItem.claim_reference === ''
      ) {
        alert('Ensure all info is added');
        return;
      }

      await put(`api/supplierClaims/${selectedItemId}`, cleansedUpdateItem);
      setSelectedItemId(null);
      setUpdateClaim({
        order_date: '',
        claim_sent_date: '',
        claim_received_date: '',
        channel_ref: '',
        pip: '',
        item: '',
        pack_size: '',
        order_qnt: 0,
        received_qnt: 0,
        missing_qnt: 0,
        channel: '',
        reason_for_return_claim: '',
        claim_reference: '',
        notes: '',
            claim_received:'NO',
    status: 'OPEN',
        id:''
      });

      await fetchSupplierClaims();
      setShowPopupConfirmClose(false);
    } catch (error) {
      console.error('Error adding invoice:', error);
    }
  };



  const handleDeleteClaim = async (id) => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to delete this claim?");
      if (isConfirmed) {
        await del(`api/supplierClaims/${id}`);
        await fetchSupplierClaims();
      }
    } catch (error) {
      console.error('Error deleting claim:', error);
    }
  };

  const handleUpdateButtonClick = (item) => {
    setSelectedItemId(item.id);
    setUpdateClaim({ ...item });
  };


  const handleCloseUpdateClaim = () => {
    setSelectedItemId(null);
    setUpdateClaim({
        order_date: '',
        claim_sent_date: '',
        claim_received_date: '',
        channel_ref: '',
        pip: '',
        item: '',
        pack_size: '',
        order_qnt: 0,
        received_qnt: 0,
        missing_qnt: 0,
        channel: '',
        reason_for_return_claim: '',
        claim_reference: '',
        notes: '',
            claim_received:'NO',
    status: 'OPEN',
        id:''
    });

  }

  const handleSearch = (e, column) => {
    const { value } = e.target;
    setSearchFilters(prevFilters => ({ ...prevFilters, [column]: value }));
  };

  const handleColumnHeaderClick = (columnKey) => {
    if (!searchIconClicked) {
      handleSort(columnKey);
    }
  };

  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortDirection('asc');
    }
  };
  

  const handleSearchIconClick = (columnKey) => {
    // setSortedColumn(null); // Reset sorting
    setFilterColumn(columnKey);
    setShowFilterPopover(true);
    setShowCalendar(columnKey === 'order_date' || 'claim_sent_date' || 'claim_received_date');
    setSearchIconClicked(true); // Set search icon clicked
  };

  const handleFilterPopoverClose = () => {
    setShowFilterPopover(false);
    setSearchIconClicked(false); // Reset search icon clicked
    setSearchFilters('');
  };

  const handleDateSelect = (date) => {
    // Adjust for time zone offset
    const timezoneOffsetInMs = date.getTimezoneOffset() * 60 * 1000;
    const adjustedDate = new Date(date.getTime() - timezoneOffsetInMs);
  
    // Format the adjusted date
    const formattedDate = adjustedDate.toISOString().split('T')[0];
  
    // Update the searchFilters state with the adjusted date
    setSearchFilters(prevFilters => ({ ...prevFilters, date: formattedDate }));
  
    // Hide the calendar after selection
    setShowCalendar(false);
  };



  const filteredData = Array.isArray(supplierClaims)
  ? supplierClaims.filter(item => {
      return Object.entries(searchFilters).every(([key, value]) => {
        if (!value) return true;
        if (key === 'order_date' || key === 'claim_sent_date' || key === 'claim_received_date') {
          const itemDate = new Date(item[key]).toISOString().split('T')[0];
          const searchDate = new Date(value).toISOString().split('T')[0];
          return itemDate.includes(searchDate);
        }
        return String(item[key]).toLowerCase().includes(value.toLowerCase());
      });
    })
  : [];

  const sortedData = sortedColumn
    ? filteredData.slice().sort((a, b) => {
        const valueA = a[sortedColumn];
        const valueB = b[sortedColumn];
        if (sortedColumn === 'order_date' || sortedColumn === 'claim_sent_date' || sortedColumn === 'claim_received_date') {
          return sortDirection === 'asc' ? new Date(valueA) - new Date(valueB) : new Date(valueB) - new Date(valueA);
        }
        if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
        if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      })
    : filteredData.slice();

const thresholdFilteredData = openClaimsFilter
    ? sortedData.filter(item => item.status === 'OPEN')
    : sortedData;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '15px',
      color: 'black',
      padding: '5px',
      borderWidth: '0px',
      width: '500px'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      width: '500px',
      fontSize: '14px',
    }),
  };

  return (
    <div>
      <h2>Supplier Claims</h2>

    <div className="add-invoice">
      <button className="add-invoice-button" onClick={() => setShowPopupClaim(true)}>
        + Add New Supplier Claim
      </button>
    </div>
    <div className="toggle-box">
    <label className="toggle-label">
  <h3>Show Open Claims</h3>
  <div className="toggle-switch">
    <input
      type="checkbox"
      id="open-close-toggle"
      checked={openClaimsFilter}
      onChange={() => setOpenClaimsFilter(!openClaimsFilter)}
    />
    <span className="slider"></span>
  </div>
</label>

</div>

      {showPopupClaim && (
        <div className="popup">
          <div className="popup-inner">
            <div className="update-form">
              <label className="form-labels">
                Order Date:
                <DatePicker
                  selected={newClaim.order_date ? new Date(newClaim.order_date) : null}
                  onChange={(date) => setNewClaim({ ...newClaim, order_date: date.toISOString().split('T')[0] })}
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </label>

              <label className="form-labels">
                Claim Sent Date:
                <DatePicker
                  selected={newClaim.claim_sent_date ? new Date(newClaim.claim_sent_date) : null}
                  onChange={(date) => setNewClaim({ ...newClaim, claim_sent_date: date.toISOString().split('T')[0] })}
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </label>

              <label className="form-labels">
                Claim Received Date:
                <DatePicker
                  selected={newClaim.claim_received_date ? new Date(newClaim.claim_received_date) : null}
                  onChange={(date) => setNewClaim({ ...newClaim, claim_received_date: date.toISOString().split('T')[0] })}
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </label>

              <label className="form-labels">
                Channel Ref:
                <input
                  className="form-fields"
                  type="text"
                  value={newClaim.channel_ref}
                  onChange={(e) => setNewClaim({ ...newClaim, channel_ref: e.target.value })}
                />
              </label>

              <label className="form-labels">
                PIP:
                <input
                  className="form-fields"
                  type="text"
                  value={newClaim.pip}
                  onChange={(e) => setNewClaim({ ...newClaim, pip: e.target.value })}
                />
              </label>

              <label className="form-labels">
                Item:
                <input
                  className="form-fields"
                  type="text"
                  value={newClaim.item}
                  onChange={(e) => setNewClaim({ ...newClaim, item: e.target.value })}
                />
              </label>

              <label className="form-labels">
                Pack Size:
                <input
                  className="form-fields"
                  type="text"
                  value={newClaim.pack_size}
                  onChange={(e) => setNewClaim({ ...newClaim, pack_size: e.target.value })}
                />
              </label>

              <label className="form-labels">
                Order Quantity:
                <input
                  className="form-fields"
                  type="number"
                  value={newClaim.order_qnt}
                  onChange={(e) => setNewClaim({ ...newClaim, order_qnt: parseInt(e.target.value) })}
                />
              </label>

              <label className="form-labels">
                Received Quantity:
                <input
                  className="form-fields"
                  type="number"
                  value={newClaim.received_qnt}
                  onChange={(e) => setNewClaim({ ...newClaim, received_qnt: parseInt(e.target.value) })}
                />
              </label>

              <label className="form-labels">
                Missing Quantity:
                <input
                  className="form-fields"
                  type="number"
                  value={newClaim.missing_qnt}
                  onChange={(e) => setNewClaim({ ...newClaim, missing_qnt: parseInt(e.target.value) })}
                />
              </label>

              <label className="form-labels">
                Channel:
                <input
                  className="form-fields"
                  type="text"
                  value={newClaim.channel}
                  onChange={(e) => setNewClaim({ ...newClaim, channel: e.target.value })}
                />
              </label>

              <label className="form-labels">
                Reason for Return/Claim:
                <div className="select-dropdown">
                <Select
                    value={
                        reason_for_return_claim && newClaim.reason_for_return_claim
                            ? { value: newClaim.reason_for_return_claim, label: newClaim.reason_for_return_claim }
                            : { value: "", label: "Select item" }
                    }
                    onChange={(selectedOption) =>
                        setNewClaim({
                            ...newClaim,
                            reason_for_return_claim: selectedOption.value, // Set the selected value
                        })
                    }
                    options={reason_for_return_claim.map(reason => ({ value: reason, label: reason }))}
                    styles={customStyles}
                />


                </div>
              </label>

              <label className="form-labels">
                Claim Reference:
                <input
                  className="form-fields"
                  type="text"
                  value={newClaim.claim_reference}
                  onChange={(e) => setNewClaim({ ...newClaim, claim_reference: e.target.value })}
                />
              </label>
              <label className='form-labels'>
                        Claim Received:
                        <select className='form-fields'
                        value={newClaim.claim_received}
                        onChange={(e) => setNewClaim({ ...newClaim, claim_received: e.target.value })}
                        >
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>

                        </select>
                    </label> 
                    <label className='form-labels'>
                        Status
                        <select className='form-fields'
                        value={newClaim.status}
                        onChange={(e) => setNewClaim({ ...newClaim, status: e.target.value })}
                        >
                        <option value="OPEN">OPEN</option>
                        <option value="CLOSE">CLOSE</option>

                        </select>
                    </label> 

              <label className="form-labels">
                Notes:
                <textarea
                  className="form-fields"
                  value={newClaim.notes}
                  onChange={(e) => setNewClaim({ ...newClaim, notes: e.target.value })}
                />
              </label>

              <button onClick={handleAddClaim} className="update-button"><BsCheckCircle /></button>
              <button onClick={() => setShowPopupClaim(false)} className="close-button">< BsXCircle /></button>
            </div>
          </div>
        </div>
      )}
<div className='table_back'>
      <table>
      <thead>
      <tr>
           {sortedData.length > 0 &&
              Object.keys(thresholdFilteredData[0]).map((key) =>
                key !== 'id' && key !== 'invoice_id' ? (
<th key={key} >
<div onClick={() => handleColumnHeaderClick(key)}>
    {columnHeaders[key]}{' '}
    {!searchIconClicked && sortedColumn === key && (sortDirection === 'asc' ? '▲' : '▼')}
  </div>

  <div>
  
  <button className="search-icon" onClick={() => handleSearchIconClick(key)}>
    < BsSearch  className="icon-search"/>
    </button>

  </div>


  {filterColumn === key && showFilterPopover && (
  <div className="popover">
    {key !== 'order_date' && key !== 'claim_sent_date' && key !== 'claim_received_date' ? (
      <input
        type="text"
        placeholder={`Search ${key}`}
        value={searchFilters[key] || ''}
        onChange={(e) => handleSearch(e, key)}
      />
    ) : (
      <DatePicker
        selected={searchFilters[key] ? new Date(searchFilters[key]) : null}
        onChange={(date) => handleSearch({ target: { value: date } }, key)} // Adjusting for handleSearch compatibility
        dateFormat="yyyy-MM-dd"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    )}
    <button onClick={handleFilterPopoverClose}>
      <BsXCircle className="icon-search" />
    </button>
  </div>
)}

</th>
                ) : null
              )}
<th>Actions</th>
          </tr>
        </thead>
      <tbody>
          {thresholdFilteredData.map((item) => (
            <React.Fragment key={item.id}>
              <tr>
                {Object.keys(item).map((key) =>
                    
                    key !== 'id' && key !== 'invoice_id' ? (
                    <td key={key}>
                    {key === 'status' ? ( 
                        <label>
                            <select className='form-fields'
                            value={item[key]}
                            onChange={(e) => handleUpdateReceivedItem({ ...item, [key]: e.target.value })}
                            >
                            <option value="OPEN">OPEN</option>
                            <option value="CLOSE">CLOSE</option>
    
                            </select>
                        </label> 
                        ) : 
                    
                    (key === 'order_date' | 'claim_sent_date' | 'claim_received_date' ? new Date(item[key]).toISOString().split('T')[0] : item[key])}</td>
                  ) : null
                )}

                <td>
                  <button className="action-button" onClick={() => handleUpdateButtonClick(item)}>
                  <BsPencilSquare />
                  </button>
                  <button className="action-button" onClick={() => handleDeleteClaim(item.id)}>
                  <BsFillTrash3Fill />
                  </button>
                </td>
              </tr>
              {selectedItemId === item.id && (
                <tr>
                  <td colSpan={Object.keys(item).length}>
                    <div>
                      <h3>Update</h3>
                      <div className="update-form">
                        {Object.keys(updateClaim).map((key) => (
                          <div key={key} className="form-group">
                            {key === 'item_name' ? (
                              <div className="field-container">
</div>

                            ) : key === 'order_date' | 'claim_sent_date' | 'claim_received_date'? (
                              <div >
                                <label className='form-labels'>{key}:</label>
                                <input className='form-fields'
                                  type="date"
                                  value={updateClaim[key]? new Date(updateClaim[key]).toISOString().split('T')[0] : ''}
                                  onChange={(e) => setUpdateClaim({ ...updateClaim, [key]: e.target.value })}
                                />
                              </div>
                            ) : key === 'claim_received' ? ( 
                                <label className='form-labels'>
                                    {key}:
                                    <select className='form-fields'
                                    value={item[key]}
                                    onChange={(e) => setUpdateClaim({ ...item, [key]: e.target.value })}
                                    >
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
            
                                    </select>
                                </label> 
                                ) : key === 'status' ? ( 
                                    <label className='form-labels'>
                                        {key}:
                                        <select className='form-fields'
                                        value={item[key]}
                                        onChange={(e) => setUpdateClaim({ ...item, [key]: e.target.value })}
                                        >
                                        <option value="OPEN">OPEN</option>
                                        <option value="CLOSE">CLOSE</option>

                                        </select>
                                    </label> 
                                    ) : 
                                
                                
                                key !== 'id' && key !== 'invoice_id' ? (
                              <div>  
                                <label className='form-labels'>{key}:</label>
                                <input className='form-fields'
                                  type={key === 'order_date' | 'claim_sent_date' | 'claim_received_date' ? key : key === 'order_qnt' |'received_qnt' |'missing_qnt' ? 'number' : 'text'}
                                  step={key === 'quantity' ? '1' : 'any'}
                                  value={updateClaim[key]}
                                  onChange={(e) => setUpdateClaim({ ...updateClaim, [key]: e.target.value })}
                                />
                              </div>
                            ) : null}
                          </div>
                        ))}
                        <button className="update-button" onClick={handleUpdateClaim}>
                        <BsCheckCircle />
                        </button>
                        <button className="close-button" onClick={handleCloseUpdateClaim}>
                        < BsXCircle />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      </div>

      {showPopupConfirmClose && (
        <div className="popup" id="notePopup">
          <div className="popup-content">
          <button class="close-button"  onClick={() => setShowPopupConfirmClose(false)}>&times;</button>
            <h3>Please confirm & complete the following before closing this return</h3>

            <div style={{ padding: '10px' }}>
            <label className='form-labels'>
                                    Claim Received:
                                    <select className='form-fields'
                                    value={updateClaim.claim_received}
                                    onChange={(e) => setUpdateClaim({ ...updateClaim, 'claim_received': e.target.value })}
                                    >
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
            
                                    </select>
                                </label> 

</div>


            {
              (updateClaim.claim_received === 'YES') && (

                  <div style={{ padding: '10px' }}>
              <label className="form-labels">
                Claim Received Date:
                <DatePicker
                  selected={updateClaim.claim_received_date ? new Date(updateClaim.claim_received_date) : null}
                  onChange={(date) => setUpdateClaim({ ...updateClaim, 'claim_received_date': date.toISOString().split('T')[0] })}
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </label>
                  </div>

              )} 

{
              (updateClaim.claim_received === 'NO') && (

                  <div style={{ padding: '10px' }}>
              <label className="form-labels">
                Reason for Claim Not Received:
                <textarea
                  className="form-fields"
                  value={updateClaim.notes}
                  onChange={(e) => setUpdateClaim({ ...updateClaim, 'notes': e.target.value})}
                />
              </label>
                  </div>

              )} 
            
            <button onClick={handleUpdateClaim}>Submit</button>
            <button onClick={() => setShowPopupConfirmClose(false)}>Cancel</button>
          </div>
        </div>
      )}

    </div>
  );
};

export default SupplierClaims;
